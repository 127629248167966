import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Paper } from '@mui/material';

const ServiceDetail = () => {
    const { serviceId } = useParams();
    const { t } = useTranslation();
    const servicesData: { name: string, description: string, id: string }[] = t('services.list', { returnObjects: true });
    const service = servicesData.find(service => service.id === serviceId);

    if (!service) {
        return <Typography variant="h6">Service not found</Typography>;
    }

    return (
        <Paper style={{ padding: '20px' }}>
            <Typography variant="h4">{service.name}</Typography>
            <Typography paragraph>{service.description}</Typography>
            {/* Add more detailed content here */}
        </Paper>
    );
};

export default ServiceDetail;