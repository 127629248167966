import React from 'react';
import Slider from "react-slick";
import { Typography, Paper, Box } from '@mui/material';

const services = [
    { id: 'web-dev', title: 'Web Development', image: '/assets/banners/home-page-graphic-1.png', description: 'High-quality web development services.' },
    // Add more services as needed
];

const ServiceSlider = () => {
    const settings = {
        dots: true,
        infinite: services.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Slider {...settings}>
                {services.map(service => (
                    <Paper key={service.id}>
                        <img src={service.image} alt={service.title} style={{ width: '100%', height: 'auto' }} />
                        {/*<Typography variant="h5">{service.title}</Typography>*/}
                        {/*<Typography>{service.description}</Typography>*/}
                    </Paper>
                ))}
            </Slider>
        </Box>
    );
};

export default ServiceSlider;