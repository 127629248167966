import React from 'react';
import { Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const content: string[] = t('privacyPolicy.content', { returnObjects: true });

    return (
        <Container>
            <Typography variant="h4" gutterBottom>{t('privacyPolicy.title')}</Typography>
            {content.map((paragraph, index) => (
                <Typography paragraph key={index}>{paragraph}</Typography>
            ))}
        </Container>
    );
};

export default PrivacyPolicy;