import React from 'react';
import {Route, Routes} from "react-router-dom";
import {About, Contact, HomePage, Services, ServiceDetail, PrivacyPolicy, TermsOfUse} from "./pages";

const AppRouter = () => {
  return <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/services/:serviceId" element={<ServiceDetail />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />
  </Routes>;
};

export default AppRouter;