import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Paper } from '@mui/material';

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <Paper style={{ padding: '20px', margin: '20px' }}>
            <Typography variant="h4">{t('aboutUs.title')}</Typography>
            <Typography paragraph>{t('aboutUs.intro')}</Typography>
            <Typography variant="h6">Mission</Typography>
            <Typography paragraph>{t('aboutUs.mission')}</Typography>
            <Typography variant="h6">Our Story</Typography>
            <Typography paragraph>{t('aboutUs.historyStory')}</Typography>
            <Typography variant="h6">Team</Typography>
            <Typography paragraph>{t('aboutUs.team')}</Typography>
            <Typography variant="h6">Culture</Typography>
            <Typography paragraph>{t('aboutUs.culture')}</Typography>
        </Paper>
    );
};

export default AboutUs;
