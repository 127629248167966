import React from 'react';
import { Box, Typography } from '@mui/material';
import ServiceSlider from "../../components/ServiceSlider/ServiceSlider";

const HomePage = () => (
    <Box sx={{ padding: 0 }}>
        <ServiceSlider />
        <OverviewSection />
        <FeaturesSection />
        {/*<TestimonialsSection />*/}
    </Box>
);

const OverviewSection = () => (
    <Box sx={{ textAlign: 'center', p: 4, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>Who We Are</Typography>
        <Typography>
            Wizard Softs is a leader in delivering innovative software solutions that drive business growth and efficiency.
        </Typography>
    </Box>
);

const FeaturesSection = () => (
    <Box sx={{ textAlign: 'center', p: 4, backgroundColor: '#e0e0e0', borderRadius: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>Our Key Features</Typography>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>Scalable and secure business solutions.</li>
            <li>Expert team with in-depth industry knowledge.</li>
            <li>Commitment to quality and excellence.</li>
        </ul>
    </Box>
);

const TestimonialsSection = () => (
    <Box sx={{ textAlign: 'center', p: 4 }}>
        <Typography variant="h4">What Our Clients Say</Typography>
        <Typography>
            "Wizard Softs has been instrumental in our digital transformation efforts, providing exceptional service and support."
            - CEO of Tech Innovate
        </Typography>
    </Box>
);

export default HomePage;