import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Box component="footer" sx={{
            p: 3,
            mt: 'auto',
            backgroundColor: (theme) => theme.palette.grey[200],
            textAlign: 'center'
        }}>
            <Typography variant="body1">
                © {new Date().getFullYear()} Wizard Softs
            </Typography>
            <Typography variant="body2">
                <MuiLink component={Link} to="/privacy">Privacy Policy</MuiLink> | <MuiLink component={Link} to="/terms">Terms of Use</MuiLink>
            </Typography>
        </Box>
    );
};

export default Footer;