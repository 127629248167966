import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {Grid, Card, CardContent, Typography, CardActions, Button, Paper} from '@mui/material';

const Services = () => {
    const { t } = useTranslation();
    // @ts-ignore
    const servicesData:[{name: string, description: string, id: string}] = t('services.list', { returnObjects: true }); // Get translated service data

    return (
        <Paper style={{ padding: '20px', margin: '20px' }}>
            <Typography variant="h4">{t('aboutUs.title')}</Typography>
        <Grid container spacing={4} columns={2}>
            {servicesData.map((service) => (
                <Grid item xs={12} sm={6} md={4} key={service.id}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {service.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {service.description}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button component={Link} to={`./${service.id}`} size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
        </Paper>
    );
};

export default Services;
