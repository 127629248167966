// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import ReactGA from 'react-ga4';
import Navigation from './components/Navigation/Navigation';
import theme from './theme';
import GlobalLayout from "./layout/GlobalLayout/GlobalLayout";
import AppRouter from "./AppRouter";

const MEASUREMENT_ID = "G-LCP77Z39SV"; // Replace with your GA4 Measurement ID
ReactGA.initialize(MEASUREMENT_ID);

const AppContent = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return (
        <GlobalLayout>
            <Navigation />
            <AppRouter />
        </GlobalLayout>
    );
};

const App = () => (
    <ThemeProvider theme={theme}>
        <Router>
            <AppContent />
        </Router>
    </ThemeProvider>
);

export default App;