import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000', // black
        },
        secondary: {
            main: '#fff', // white
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h5: {
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: 0.5,
        },
    },
});

export default theme;
