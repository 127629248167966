import React, { useEffect } from 'react';
import { Container, Box } from '@mui/material';
import Footer from "../../components/Footer/Footer";

// @ts-ignore
const GlobalLayout = ({ children }) => {
    useEffect(() => {
        document.body.style.overflowX = 'hidden';
        return () => {
            document.body.style.overflowX = 'auto';
        };
    }, []);

    return (
        <>
            {children}
            <Footer />
        </>
    );
};

export default GlobalLayout;