import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
    const { t } = useTranslation();

    return (
        <AppBar position="static">
            <Toolbar>
                {/*<IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>*/}
                {/*    <MenuIcon />*/}
                {/*</IconButton>*/}
                <Typography color="inherit" variant="h6" component={Link} to="/" sx={{ flexGrow: 1 }} style={{textDecoration: 'none'}}>
                    {t('appName')}
                </Typography>
                <Button color="inherit" component={Link} to="/">{t('home.title')}</Button>
                <Button color="inherit" component={Link} to="/about">{t('about.title')}</Button>
                <Button color="inherit" component={Link} to="/services">{t('services.title')}</Button>
                <Button color="inherit" component={Link} to="/contact">{t('contact.title')}</Button>
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;