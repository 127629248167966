import React from 'react';
import { Typography, Paper, Link, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    return (
        <Paper sx={{ p: 4, mt: 2, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>{t('contact.title')}</Typography>
            <Typography variant="subtitle1">
                {t('contact.description')}
                <Link href={`mailto:${t('contact.email')}`} sx={{ ml: 1 }}>{t('contact.email')}</Link>.
            </Typography>
            <Box sx={{ mt: 2, p: 2, backgroundColor: 'warning.main', color: 'warning.contrastText' }}>
                <Typography>
                    {t('contact.warning')}
                </Typography>
            </Box>
        </Paper>
    );
};

export default Contact;
